import {base_host} from '../utils/constants';
import React, { useState, useEffect } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import Brief from '../components/home/brief';
import SlideShow from '../components/home/slideshow';
import Services from '../components/home/services';
import Story from '../components/about/story';
import Team from '../components/about/team';
import AboutUs from '../components/about/aboutus';
import KinsNeighbor from '../components/kinsneighbor';

function About(){
    return(        
        <>
            <AboutUs/>
            <Story/>                        
            <Team/>
            <KinsNeighbor/>
        </>
    )
}

export default About;