import { ButtonMe } from "../buttons/mainbutton";
import { Title } from "../title";
import BlogCard from "./blogcard";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Pagination,Navigation,Scrollbar } from 'swiper';
import axios from 'axios';
import React, { useState,useEffect  } from 'react';
import Moment from "react-moment";


function Blogs(){

    const [apiTrans, setApiTrans] = useState(null);    
    const [upcheckme, setUpcheck] = useState(0);    


    useEffect(()=>{        
        if(apiTrans == null){     
            var setapiObjMain = []     
            axios.get('https://blog.kinships.co/wp-json/wp/v2/posts?_embed&filter[orderby]=date&order=desc')
            .then(res => {  
                console.log(res);
                (res.data).forEach((element,index)=>{
                    if(index < 3){
                        var setapiObj = {}   
                        setapiObj.title = element.title.rendered;
                        setapiObj.link = element.link;
                        setapiObj.date = element.date;
                        setapiObj.author = element._embedded.author[0].name;
                        setapiObj.img = element._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url;
                        setapiObjMain.push(setapiObj)
                    }
                })
                // setTimeout(()=>{
                    setApiTrans(setapiObjMain) 
                // },2000)                                                                                                     
            })
            
        }
    })


    if(apiTrans !== null){
        return(
            <div className="secme blogs wid85">
                <Title titlestring="Our blogs" classes="justify-center align-center"/>
                <div className="blogsin wid100 mob100">
                    {
                        window.innerWidth > 480 ?                                                                                       
                            apiTrans.map((element,index)=>{  
                                if(index < 3){
                                    return (<BlogCard name={element.author} short={element.title} img={element.img} date={<Moment format="DD MMMM YYYY">{element.date}</Moment>} link={element.link}/>)
                                }
                            }) : 
                            <Swiper
                            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                            spaceBetween={10}
                            centeredSlides={true}
                            slidesPerView={1}                                         
                            autoplay
                            pagination
                            >
                                {
                                    apiTrans.map((element,index)=>{                            
                                        if(index < 3){
                                            return (
                                                <SwiperSlide>                                        
                                                    <BlogCard name={element.author} short={element.title} img={element.img} date={<Moment format="DD MMMM YYYY">{element.date}</Moment>} link={element.link}/>
                                                </SwiperSlide>
                                            )
                                        }
                                    })
                                }
                            </Swiper>
                    }
                    
                </div>
            </div>
        )  
    }else{
        return null
    }    
}

export default Blogs;