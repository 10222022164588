import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";

function Story(){
    return(
        <div className="wid100 story lightbluebg flex secme align-center justify-center" id="story">
        <div className="flex wid85 align-center justify-center secme">
            <div className="left">
                <Title classes={window.innerWidth < 480 ? `align-center justify-center flex` : `align-center justify-center flex`}  titlestring="Our Story"/>     
                {/* {
                    window.innerWidth < 480 ? 
                    <div className="right">
                        <div className="whitebox">
                            <div className="videobox">
                            <iframe src="https://www.youtube.com/embed/igKdgta23gg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div> : null
                }   */}
                <Para parastring={<><em>“The most frustrating part of it was feeling helpless. There was nothing I could do. It was the longest night of my life.”</em><br/><br/>Several years ago, Saurav, our Founder was overseas in a small island country  on work and was faced with an untimely family medical emergency he could nothing about till many hours later when the first flight was available out of the island. He felt the acute lack of a support system back home which could deal with the emergency on his behalf. He felt the urgent need to be with his family but to no avail.  That was the night he knew things needed to change for the better. He decided that there needed to be a support system in place for folks who stay alone at home and need assistance.<br/><br/>Such a solution was unheard of at the time but Saurav started working on building the framework of the support system then. The result was - Kinships, a full-service assisted mobility and home care company aimed at the end to end management of patients from home to hospital and back home again for people across all age groups.</>} classes="text-center"/>                
            </div>
            {/* {
                    window.innerWidth > 480 ? 
                    <div className="right">
                        <div className="whitebox">
                            <div className="videobox">
                            <iframe src="https://www.youtube.com/embed/igKdgta23gg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div> : null
                }  */}
        </div>
        </div>
    )
}

export default Story;