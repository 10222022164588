import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export function ButtonMe(props){
    return(
        <>
        {
            props.alink != undefined ? 
            <><a href={props.alink} className="buttonmea" target="_blank"><div className={props.classes != undefined ? `buttonme `+props.classes : `buttonme`}><p>{props.buttext}</p></div></a></> :
            (
                props.hashlink != undefined ?                
                <HashLink to={props.link} className="buttonmea"><div className={props.classes != undefined ? `buttonme `+props.classes : `buttonme`}><p>{props.buttext}</p></div></HashLink> :
                <Link to={props.link} className="buttonmea"><div className={props.classes != undefined ? `buttonme `+props.classes : `buttonme`}><p>{props.buttext}</p></div></Link>
            )
        }               
        </>
    )
}