import React, { useState, useEffect } from 'react';
import {base_host} from '../utils/constants';
import axios from 'axios';
import Brief from '../components/home/brief';
import SlideShow from '../components/home/slideshow';
import Services from '../components/home/services';
import Testimonials from '../components/home/testimonials';
import Trusted from '../components/home/trusted';
import KinsNeighbor from '../components/kinsneighbor';
import Blogs from '../components/home/blogs';

function Home(){
    return(        
        <>
            <SlideShow/>
            <Brief/>                        
            <Services/>
            <Testimonials/>
            <KinsNeighbor/>
            <Trusted/>
            <Blogs/>         
        </>
    )
}

export default Home;