import { ButtonMe } from "../buttons/mainbutton";
import { Title } from "../title";
import React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

function BookNow(props){
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [formSuccess, setFormSuccess] = useState(false)
    const [formFailed, setFormFailed] = useState(false)
    const onSubmit = data => {    
        var formDataObj
        if(props.type == "homecare" ){    
            formDataObj = {
                'fullname': data.fullname,
                'contact': data.contact,
                'pname': data.patientname,
                'page': data.patientage,
                'sertype': data.servtype,
                'attendgender': data.gender,
                'medical': data.history,
                'location': data.address                
            }
        }else{            
            formDataObj = {
                'fullname': data.fullname,
                'contact': data.contact,
                'email': data.emailid,
                'serdate': data.servdate,
                'pickuploc': data.address,
                'droploc': data.drop,
                'service': data.servicename
            }
        }         
        var url;
        props.type == "homecare" ? url = 'https://kinships.co/api/homecare.php' : url = 'https://kinships.co/api/service.php'
        axios.post(url, formDataObj)
        .then(
            (result) => { 
                if(result.status == 201 && result.data.status=="success"){                           
                    setFormSuccess(true)
                    setFormFailed(false)
                }else{                                
                    setFormFailed(true)
                }
            })
        .catch(error => {                        
            setFormFailed(true)
        })          
    };

    const changePropType = (e)=>{
        console.log(e);
        if(e.target.value == "Home care service"){
            props.changetype("homecare")
        }else{
            props.changetype("nothomecare")
        }
    }

    if(!formSuccess){
        return(
            <div className={`bluebg booknow wid100 ${props.from !== "bookpopup" ? " secme" : ""}`} id="booknow" style={{marginTop:"0px!important"}}>
                <Title titlestring="Book Now" classes="justify-center align-center whiteme"/>
                <div className="bookin wid85 whitebox blueshadow">
                    <form onSubmit={handleSubmit(onSubmit)} className="flex justify-between align-center column">
                        <div className="inputboxes flex justify-between align-center row-wrap josefin mob-column mob90">
                            {
                                props.from == "bookpopup" ?
                                <><div className="inputbox full">
                                    <div className="inputname">select the service you want?</div>    
                                    <select {...register("servicename", { required: true })} onChange={e=>changePropType(e)} >
                                        <option>In hospital support service</option>
                                        <option>Assisted mobility service</option>
                                        <option>Home care service</option>
                                    </select>
                                    {errors.servicename && <p>This field is required</p>}
                                </div></> : null
                            }
                            {
                                props.type == "homecare" ?
                                <><div className="inputbox">
                                    <div className="inputname">your full name</div>    
                                    <input type="text" {...register("fullname", { required: true })} />
                                    {errors.fullname && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox">
                                    <div className="inputname">your Contact number</div>    
                                    <input type="text" {...register("contact", { required: true })} />
                                    {errors.contact && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox">
                                    <div className="inputname">patient's name</div>    
                                    <input type="text" {...register("patientname", { required: true })} />
                                    {errors.patientname && <p>This field is required</p>}
                                </div>       
                                <div className="inputbox">
                                    <div className="inputname">patient's age</div>    
                                    <input type="number" {...register("patientage", { required: true })} />
                                    {errors.patientage && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox">
                                    <div className="inputname">Type of service</div> 
                                    <select {...register("servtype", { required: true })}>
                                        <option>12 hours</option>
                                        <option>24 hours</option>
                                    </select>
                                    {errors.servtype && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox">
                                    <div className="inputname">Gender of desired attendant</div>    
                                    <select {...register("gender", { required: true })}>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                    {errors.gender && <p>This field is required</p>}
                                </div>
                                <div className="inputbox full">
                                    <div className="inputname">Brief medical history</div>    
                                    <textarea {...register("history", { required: true })} />
                                    {errors.history && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox full">
                                    <div className="inputname">Your location</div>    
                                    <textarea {...register("address", { required: true })} />
                                    {errors.address && <p>This field is required</p>}
                                </div>                             
                                </> :                            
                                <>                        
                                <div className="inputbox">
                                    <div className="inputname">Full name</div>    
                                    <input type="text" {...register("fullname", { required: true })} />
                                    {errors.fullname && <p>This field is required</p>}
                                </div>      
                                <div className="inputbox">
                                    <div className="inputname">Service date</div>    
                                    <input type="date" {...register("servdate", { required: true })} />
                                    {errors.servdate && <p>This field is required</p>}
                                </div>                             
                                <div className="inputbox full">
                                    <div className="inputname">Pickup address</div>    
                                    <textarea {...register("address", { required: true })} />
                                    {errors.address && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox full">
                                    <div className="inputname">Drop address</div>    
                                    <textarea {...register("drop", { required: true })} />
                                    {errors.drop && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox">
                                    <div className="inputname">Contact no.</div>    
                                    <input type="text" {...register("contact", { required: true })} />
                                    {errors.contact && <p>This field is required</p>}
                                </div> 
                                <div className="inputbox">
                                    <div className="inputname">Email</div>    
                                    <input type="text" {...register("emailid", { required: true })} />
                                    {errors.emailid && <p>This field is required</p>}
                                </div>
                                </>
                            }
                        </div>
                        <input type="submit" value="Submit request" className="inputsubmit ubuntu"/>
                    </form>
                </div>
            </div>
        )
    }else{
        return(
            <>
                <Player
                    autoplay
                    loop
                    src="./assets/lottie/submit.json"
                    style={{width:"320px"}}
                >
                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                </Player>
                <h3 className="josefin wid90 text-center">Your request is successfully sent.<br/>We will get back to you at the earliest.</h3>
                <div className="buttonme blue" style={{position:"relative",margin:"35px auto"}} onClick={(()=>{setFormSuccess(false);setFormFailed(false);})}>Send another request</div>
            </>
        )
    }
}

export default BookNow;