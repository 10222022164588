import { Title } from "../title";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { Autoplay, Pagination, Navigation, Scrollbar } from "swiper";

function Trusted() {
  return (
    <div className="secme trusted">
      <Title classes="justify-center align-center" titlestring="Trusted By" />
      <Swiper
        modules={[Pagination, Scrollbar, Autoplay]}
        spaceBetween={20}
        centeredSlides={false}
        slidesPerView={window.innerWidth > 480 ? 3 : 1}
        autoplay={{
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        className="text-center"
      >
        <SwiperSlide>
          <img src="./assets/img/manipal.png" alt="Manipal" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./assets/img/talerang.jpg" alt="Talerang" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./assets/img/hinduja.png" alt="Hinduja" />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="./assets/img/hindustan_uni.png"
            alt="Hindustan University"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./assets/img/novo.png" alt="Novo" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="./assets/img/IQVIA.png" alt="IQVIA" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Trusted;
