import { ButtonMe } from "../components/buttons/mainbutton";
import { Title } from "../components/title";
import React from "react";
import { useForm } from "react-hook-form";
import { Para } from "../components/para";
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KinsNeighbor from "../components/kinsneighbor";
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Player, Controls } from '@lottiefiles/react-lottie-player';


function ContactUs(props){
    const [formSuccess, setFormSuccess] = useState(false)
    const [formFailed, setFormFailed] = useState(false)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    // const onSubmit = data => {        
    //     const formDataObj = {
    //         'fullname': data.fullname,
    //         'contact': data.contact,
    //         'email': data.emailid,
    //         'location': data.address,
    //         'message': data.message
    //     }                
    //     axios.post('https://kinships.co/api/contact.php', formDataObj)
    //     .then(
    //         (result) => { 
    //             if(result.status == 201 && result.data.status=="success"){                           
    //                 setFormSuccess(true)
    //                 setFormFailed(false)
    //             }else{                                
    //                 setFormFailed(true)
    //             }
    //         })
    //     .catch(error => {                        
    //         setFormFailed(true)
    //     })          
    // };

    return(
        <>
        <div className="secme bluebg booknow wid100" style={{margin:"0px!important"}}>
            <Title titlestring="Contact Us" classes="justify-center align-center whiteme"/>
            <div className="flex align-top mob-column wid85">
                <div className="wid85">
                    <Para parastring="We believe in caring for our valued users and their loved ones and enabling a smooth transition from home to healthcare with a dependable and tech-enabled solution. Our vehicles, driver and professional guardians, take on the role of family on a daily basis with positivity and comforting smiles. Our service is a premium care package with affordability at its heart for the multifaceted needs of patients/users." classes="flex align-center justify-center wid90 whiteme mob-text-center mob100 margin0"/>
                    <br/><br/>
                    <div className="contacts flex column mob-justify-center whiteme">                        
                        <div className="flex mob-justify-center" style={{}}>
                            <FontAwesomeIcon style={{marginTop:"20px",marginRight: "10px"}} icon={faPhone}/>
                            <a href="tel:+91 9892305766"><Para parastring="+91 9892305766"/></a>
                        </div>
                        <div className="flex mob-justify-center">
                            <FontAwesomeIcon style={{marginTop:"20px",marginRight: "10px"}} icon={faEnvelope}/>
                            <a href="mailto:support@kinships.co"><Para parastring="support@kinships.co"/></a>
                        </div>
                        <div className="flex mob-justify-center" style={{width:window.innerWidth > 480 ? "60%" : "100%",}}>
                            <FontAwesomeIcon style={{marginTop:"20px",marginRight: "10px"}} icon={faLocationDot}/>
                            <Para parastring="7th Floor, 705 Unit No., Veena Killedar Industrial Estate, Jacob Circle, Mumbai - 400011" classes="mob-text-center"/>
                        </div><br/>
                    </div>
                </div>
                <div className="bookin wid85 whitebox blueshadow mob100" style={{marginTop:"0px!important"}}>
                    {
                        !formSuccess ?
                        <form
                        //  onSubmit={handleSubmit(onSubmit)} 
                         className="flex justify-between align-center column">
                        <div className="inputboxes flex justify-between align-center row-wrap josefin mob-column mob90">                                             
                            <div className="inputbox">
                                <div className="inputname">Full name</div>    
                                <input type="text" {...register("fullname", { required: true })} />
                                {errors.fullname && <p>This field is required</p>}
                            </div> 
                            <div className="inputbox">
                                <div className="inputname">Your location</div>    
                                <input type="text" {...register("address", { required: true })} />
                                {errors.address && <p>This field is required</p>}
                            </div> 
                            <div className="inputbox">
                                <div className="inputname">Contact number</div>    
                                <input type="text" {...register("contact", { required: true })} />
                                {errors.contact && <p>This field is required</p>}
                            </div> 
                            <div className="inputbox">
                                <div className="inputname">Email</div>    
                                <input type="text" {...register("emailid", { required: true })} />
                                {errors.emailid && <p>This field is required</p>}
                            </div>                
                            <div className="inputbox full">
                                <div className="inputname">Your message</div>
                                <textarea {...register("message", { required: true })} />
                                {errors.message && <p>This field is required</p>}
                            </div>           
                        </div>
                        <input type="submit" value="Send" className="inputsubmit ubuntu"/>
                        </form> :
                        <>
                            <Player
                                autoplay
                                loop
                                src="./assets/lottie/submit.json"
                                style={{width:"320px"}}
                            >
                                <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                            </Player>
                            <h3 className="josefin wid90 text-center">Your message is successfully sent.<br/>We will get back to you at the earliest.</h3>
                            <div className="buttonme blue" style={{position:"relative",margin:"35px auto"}} onClick={(()=>{setFormSuccess(false);setFormFailed(false);})}>Send another message</div>
                        </>
                    }
                    
                </div>
            </div>
        </div>
        <KinsNeighbor/>
        </>
    )    
}

export default ContactUs;