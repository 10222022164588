import { Title } from "./title";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Pagination,Navigation,Scrollbar } from 'swiper';
import { Para } from "./para";

function KinsNeighbor(){
    return(
        <div className="secme kinsneighbor">
            <Title classes="justify-center align-center" titlestring="Kins In Your Neighbourhood"/>
            <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={0}
            centeredSlides={(window.innerWidth > 480) ? true : false}
            slidesPerView={(window.innerWidth > 480) ? 3.2 : 1} 
            navigation                        
            autoplay={{                  
                disableOnInteraction: false,
            }}
            >
                <SwiperSlide>
                    <div className="whitebox">
                        <img src="./assets/img/mumbai.png" className="cityimg"/>
                        <Para parastring="Mumbai" classes="cityname"/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="whitebox">
                        <img src="./assets/img/pune.png" className="cityimg"/>
                        <Para parastring="Pune" classes="cityname"/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="whitebox">
                        <img src="./assets/img/bengaluru.jpg" className="cityimg"/>
                        <Para parastring="Bengaluru" classes="cityname"/>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default KinsNeighbor;