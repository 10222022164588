import { Fade } from "react-reveal";
import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";

function TeamIn(props){
    return(
        <Fade bottom>
        <div className="teamme whitebox blueshadow flex column wid90 align-center">
            <div className="image wid100"><img src={`./assets/img/team/`+props.img}/></div>
            <div className="myname josefin">{props.name}</div>
            <div className="designation josefin">{props.design}</div>
        </div>
        </Fade>
    )
}

export default TeamIn;