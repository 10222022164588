import { Title } from "../title";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Pagination,Navigation,Scrollbar } from 'swiper';
import { Para } from "../para";

function TestiSlide(props){
    return(        
        <div className="testislides flex align-center justify-center wid85 mob-column mob90">
            <div className="testivids">                            
                <div className="videodiv">
                    <img src="./assets/img/testibg.png"/>
                    {props.vidimg}                    
                </div>
            </div>
            <div className="testiparas">
                <h3>{props.name}</h3>
                <p>"{props.para}"</p>
            </div>
        </div>           
    )
}

export default TestiSlide;