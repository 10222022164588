import { Parallax } from 'react-scroll-parallax';
import BgElement from '../components/bgelement';

export default function BgScroll(){

    // var getrand = ()=>{
    //     var random = Math.ceil(Math.random() * 10) * (Math.round(Math.random()) ? 1 : -1)
    //     console.log(random)
    //     return random
    // }

    return(
        <div style={{position:"absolute",zIndex:"-1",width:"100%",overflow:"hidden",margin:"auto",left:"0",right:"0",height:"inherit",top:"0"}}>
            
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>
            <BgElement/>            
            <BgElement/>
            <BgElement/>
            <BgElement/>            
            <BgElement/>
            <BgElement/>
            <BgElement/>            
        </div>
    )
}