import { Fade } from "react-reveal";

export function Title(props){
    return(
        <Fade bottom>
        <div className={props.classes != undefined ? `titlediv flex column `+props.classes : `titlediv flex column`}>
            <h1 className={`h1title`}>{props.titlestring}</h1>
            <div className="titleunderline">
                <div className="sec1"></div>
                <div className="sec2"></div>
                <div className="sec3"></div>
            </div>
        </div>
        </Fade>
    )
}