import { Title } from "../title";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Pagination,Navigation,Scrollbar } from 'swiper';
import { Para } from "../para";
import TestiSlide from "./testislide";

function Testimonials(){
    return(
        <div className="secme lightbluebg testimonials">
            <Title titlestring="Testimonials" classes="justify-center align-center"/>
            <div className="bigcircle flex align-center justify-center">
                <div className="smallcircle"></div>
            </div>
            <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={10}
            centeredSlides={true}            
            slidesPerView={1} 
            navigation                                    
            >
                <SwiperSlide>
                    <TestiSlide 
                        vidimg={<><iframe src="https://www.youtube.com/embed/MCqo4JcX7OM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></>}
                        name="Asha Turakhia"
                        para="It's rude to continuously ask friends and relatives to accompany us to the doctor and cause regular disruptions to their routines. Since my husband's hospital trips are scheduled frequently, and he was uncomfortable with going alone, we really felt the need to use Kinships’ services. 
                        Aishwarya, our assigned caretaker, always keeps me updated and takes care of all hospital formalities. She’s become family now."
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TestiSlide 
                        vidimg={<><img src="./assets/img/sakarminwala.jpg" className="videodivimg"/></>}
                        name="Sakar Minwala"
                        para="Our experience with Kinships has been very nice. Their personalised care and transparent communications have been most encouraging. They have been a source of great support for us and have made themselves most useful with their prompt and reliable services. I would definitely recommend their services to senior citizens who could do with some help."
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <TestiSlide 
                        vidimg={<><img src="./assets/img/kevinmarolia.jpg" className="videodivimg"/></>}
                        name="Kevin Marolia"
                        para="I couldn't walk or stand. The driver picked me up from my doorstep and dropped me at the hospital entrance, where an associate was waiting with a wheelchair. She was there with us the entire time. This service is a godsend for people with disabilities and senior citizens. It was a great experience. Awesome service."
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Testimonials;