import { ButtonMe } from "../buttons/mainbutton";
import { Title } from "../title";
import ServCard from "./servcard";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Pagination,Navigation,Scrollbar } from 'swiper';

function Services(){
    return(
        <div className="secme bluebg services wid100">
            <Title titlestring="Our services" classes="justify-center align-center whiteme"/>
            <div className="servin wid85 mob100">
                {
                    window.innerWidth > 480 ?                     
                        <>
                        <ServCard name="Home Care" short="A companion for you, always" img="serv1.jpg" link="./services/home-care"/>
                        <ServCard name="Assisted Mobility" short="Go places with our kins" img="serv2.jpg" link="./services/mobility"/>
                        <ServCard name="In Hospital Support" short="Never be alone at the hospital" img="serv3.jpg" link="./services/in-hospital-support"/>
                        </>
                        : 
                        <Swiper
                        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                        spaceBetween={10}
                        centeredSlides={true}
                        slidesPerView={1}                                         
                        autoplay={{        
                            delay: 4000,          
                            disableOnInteraction: false,
                        }}
                        pagination
                        >
                            <SwiperSlide>
                                <ServCard name="Home Care" short="A companion for you, always" img="serv1.jpg" link="./services/home-care"/>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ServCard name="Assisted Mobility" short="Go places with our kins" img="serv2.jpg" link="./services/mobility"/>
                            </SwiperSlide>
                            <SwiperSlide>                            
                                <ServCard name="In Hospital Support" short="Never be alone at the hospital" img="serv3.jpg" link="./services/in-hospital-support"/>
                            </SwiperSlide>
                        </Swiper>
                }
                
            </div>
        </div>
    )
}

export default Services;