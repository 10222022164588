import { useEffect, useState } from "react";
import {
    useLocation,
    useNavigate,
    useParams
  } from "react-router-dom";

export function TitleMe(props){
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    const [currlocate, setCurrLocate] = useState(null)    
    if (location.pathname == "/" || location.pathname == "/home") {
        document.title = "Kinships - Home";        
    }
    else if (location.pathname == "/about") {                
        document.title = "Kinships - About";        
    }
    else if (location.pathname == "/services/in-hospital-support") {                
        document.title = "Kinships - In Hospital Support Services"; 
    }
    else if (location.pathname == "/services/mobility") {                
        document.title = "Kinships - Assisted Mobility Services"; 
    }
    else if (location.pathname == "/services/home-care") {                
        document.title = "Kinships - Home Care Services"; 
    }
    else if (location.pathname == "/contact") {                
        document.title = "Kinships - Contact"; 
    }
    else {
        document.title = "404 Page not found | Kinships";        
    }
    useEffect(()=> {
        // const { match, location, history } = props             
        if (location.pathname !== currlocate) {
            if (location.pathname == "/" || location.pathname == "/home") {
                document.title = "Kinships - Home";        
            }
            else if (location.pathname == "/about") {                
                document.title = "Kinships - About";        
            }
            else if (location.pathname == "/services/in-hospital-support") {                
                document.title = "Kinships - In Hospital Support Services"; 
            }
            else if (location.pathname == "/services/mobility") {                
                document.title = "Kinships - Assisted Mobility Services"; 
            }
            else if (location.pathname == "/services/home-care") {                
                document.title = "Kinships - Home Care Services"; 
            }
            else if (location.pathname == "/contact") {                
                document.title = "Kinships - Contact"; 
            }
            else {
                document.title = "404 Page not found | Kinships";        
            }
            
            setCurrLocate(location.pathname)       
            window.scrollTo(0, 0);                                         
        }
    })
    
    return(
        null
    );
}


