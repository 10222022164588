import {base_host} from '../utils/constants';
import React, { useState, useEffect } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import KinsNeighbor from '../components/kinsneighbor';
import Faq from '../components/services/faq';
import SerInfo from '../components/services/infodiv';
import BookNow from '../components/services/booknow';

function ServicesPageMobility(props){
    return(        
        <>
            <SerInfo
                serimg={ window.innerWidth > 480 ? "mobilitybanner.jpg" : "mob-mobility.jpg"}                
                name="Assisted Mobility Services"
                quality={[
                    {
                        quality:"GDA certified attendants",
                        image:"certified.png"
                    },
                    {
                        quality:"Sanitised vehicles",
                        image:"sanitized.png"
                    },
                    {
                        quality:"No surge pricing",
                        image:"nosurge.png"
                    },
                    {
                        quality:"Assured rides",
                        image:"assured-rides.png"
                    },
                ]}
                infopara="Going to the hospital alone could be intimidating whether it’s for a check-up or an overnight stay. We are here to help you and your loved ones with accompanied travel to and from the hospital, assistance with documentation, diagnostics, collection of reports, and anything else you may need. We ensure that your hospital visits are stress-free."
            />
            <Faq
                faqs={
                    [
                        {
                            question:"What is the difference between Kinships and any other cab-hailing service?",
                            answer:"Our program provides trained care professionals and drivers to accompany you throughout your journey, assist you with every aspect of your task and bring you home safely. Our vehicles are outfitted with cushions, onboard snacks, amenities, and all your travel needs. Unlike other cab services, our vehicles and people stay with our clients till their requirement is over, and they are brought home safely."                        
                        },
                        {
                            question:"How can I ensure my loved ones are safe with your team?",
                            answer:"We recruit professionals from trusted sources and conduct rigorous background checks. Our team is professionally trained with necessary skills, including specialised training for comprehensive support assistance to our users. Additionally, we carefully monitor the entire service experience during deployment. In the case of assisted mobility-oriented services, users can also track their family members’ and friends’ locations at all times."
                        }
                    ]
                }
            />
            <BookNow/>
            <KinsNeighbor/>
        </>
    )
}

export default ServicesPageMobility;