import { Title } from "../title";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Pagination,Navigation,Scrollbar } from 'swiper';
import { Para } from "../para";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { useEffect, useState } from 'react';
import BookNow from "../services/booknow";

function SlideShow(){
    const [changeprop, setChangePropType] = useState("nothomecare");

    const makeprop = (e)=>{
        console.log("makeprop",e)        
        setChangePropType(e)
               
    }

    return(        
        <div className="slideshow wid85">
            <div className="slideshowin">
                <Swiper
                modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                spaceBetween={10}
                centeredSlides={true}
                slidesPerView={1}           
                pagination={true}                              
                autoplay={{          
                    delay: 4000,        
                    disableOnInteraction: false,
                }}
                >                    
                    <SwiperSlide>
                        <Popup 
                            trigger={<Link to="#">
                                { 
                                window.innerWidth > 480 ?
                                    <img src="./assets/img/2.jpg" className="slideimg"/>
                                    : <img src="./assets/img/mob2.jpg" className="slideimg"/>
                                }
                            </Link>} 
                            position="center"
                        >
                            <div><BookNow from="bookpopup" type={changeprop} changetype={makeprop}/></div>
                        </Popup>                        
                    </SwiperSlide>                     
                    <SwiperSlide>
                        <Popup 
                            trigger={<Link to="#">
                                {                                     
                                    window.innerWidth > 480 ?
                                        <img src="./assets/img/1.jpg" className="slideimg"/>
                                        : <img src="./assets/img/mob1.jpg" className="slideimg"/>                                       
                                }
                            </Link>} 
                            position="center"
                        >
                            <div><BookNow from="bookpopup" type={changeprop} changetype={makeprop}/></div>
                        </Popup> 
                    </SwiperSlide> 
                    <SwiperSlide>
                    <Popup 
                            trigger={<Link to="#">
                                {                                     
                                    window.innerWidth > 480 ?
                                        <img src="./assets/img/3.jpg" className="slideimg"/>
                                        : <img src="./assets/img/mob3.jpg" className="slideimg"/>                                       
                                }
                            </Link>} 
                            position="center"
                        >
                            <div><BookNow from="bookpopup" type={changeprop} changetype={makeprop}/></div>
                        </Popup> 
                    </SwiperSlide>       
                    <SwiperSlide>
                        <a href="https://www.manipalhospitals.com/mitra-lp" target="_blank" noreferrer noopener>
                            { 
                             window.innerWidth > 480 ?
                                <img src="./assets/img/4.jpg" className="slideimg"/>
                                : <img src="./assets/img/mob4.jpg" className="slideimg"/>
                            }
                        </a>
                    </SwiperSlide>              
                </Swiper>
            </div>
        </div>
    )
}

export default SlideShow;