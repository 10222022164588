import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade } from "react-reveal";
import { ButtonMe } from "../buttons/mainbutton";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function ServCard(props){
    return(
        <Fade bottom>
        <div className="whitebox blueshadow servcard">
            <div className="servcardin">
                <div className="gradient"></div>     
                <div className="serinfo">
                <Fade left><div className="servname">{props.name}</div></Fade>
                <Fade right><div className="servshort">{props.short}</div></Fade>
                </div>
                <img src={`./assets/img/`+props.img}/>
                <ButtonMe 
                    buttext={<>Know more <FontAwesomeIcon icon={faChevronRight} /></>} 
                    link={props.link} 
                    classes="blue"
                />
            </div>
        </div> 
        </Fade>
    )
}