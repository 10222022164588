import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

export function Para(props){    
    if(props.linkto !== undefined){
        return(
            <Fade bottom>
                <a href={props.linkto} target="_blank" noreferrer><p className={props.classes != undefined ? `pstring `+props.classes : `pstring`}>{props.parastring}</p></a>
            </Fade>
        )
    }else{        
        return(
            <Fade bottom>
                <p className={`pstring `+props.classes}>{props.parastring}</p>
            </Fade>
        )
    }    
}