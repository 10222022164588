import {base_host} from '../utils/constants';
import React, { useState, useEffect } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import KinsNeighbor from '../components/kinsneighbor';
import Faq from '../components/services/faq';
import SerInfo from '../components/services/infodiv';
import BookNow from '../components/services/booknow';

function ServicesPageHomecare(props){
    return(        
        <>
            <SerInfo                
                serimg={ window.innerWidth > 480 ? "homecarebanner.jpg" : "mob-homecare.jpg"}                
                name="Home Care Services"
                quality={[
                    {
                        quality:"GDA Certified Attendants",
                        image:"certified.png"
                    },
                    {
                        quality:"Physiotherapists",
                        image:"physio.png"
                    },
                    {
                        quality:"Nannies",
                        image:"nannies.png"
                    },
                    {
                        quality:"Customised Duty Hours",
                        image:"customduty.png"
                    },
                ]}
                infopara="At some point, many of us need that extra helping hand to help us over periods of difficult health or when we return from a stay at the hospital. Once you return home we provide follow up care at home as prescribed by your hospital. Be it a change of dressing or the administering of an injection or even the adjustment of a catheter, we’ll be there for you. There may also be a requirement for someone to be with patients post discharge  or even senior citizens to help them with their daily routine, hygiene, meals and medicines. We are always at hand to help out with our warm and caring nurses and attendants."
            />
            <Faq
                faqs={[
                    {
                        question:"What can I expect a home care nurse/attendant to do for my loved ones at home?",
                        answer: <>We understand how difficult it is to entrust your family's care to someone else.
                        Kinships will assist your loved ones with daily mobility requirements, medicine administration, and feed them meals, and will also be by their side in the event of an emergency.<br/>
                        Whether it is taking care of their dietary needs or simply providing companionship, you can stay worry-free as we care for your family like our own.</>                        
                    },  
                    {
                        question:"How do I ensure that the person caring for my loved one post their surgery is trustworthy and safe?",
                        answer: <>We recruit professionals from trusted sources and conduct rigorous background checks. Our team is professionally trained with necessary skills, including specialised training for comprehensive support assistance to our users..</>                        
                    },
                ]}
            />
            <BookNow type="homecare"/>
            <KinsNeighbor/>
        </>
    )
}

export default ServicesPageHomecare;