
function Briefright(){
    return(        
        <div className="right mob100">
            <div className="whitebox">
                <div className="videobox">
                <iframe src="https://www.youtube.com/embed/n-vGoEDD0dg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Briefright;