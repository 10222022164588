import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade } from "react-reveal";
import { ButtonMe } from "../buttons/mainbutton";
import { faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';

export default function BlogCard(props){
    return(
        <Fade bottom>
        <div className="whitebox blogcard">
            <div className="blogcardin">                   
                <img src={props.img}/>
                <div className="serinfo">
                <Fade bottom><div className="servshort ubuntu">{props.short}</div></Fade>
                <div className="metainfo flex align-center">
                    <Fade bottom><div className="servname">{props.date}</div></Fade>
                    <FontAwesomeIcon icon={faCircle}/>
                    <Fade bottom><div className="servname">{props.name}</div></Fade>
                </div>
                </div>
                <ButtonMe 
                    buttext={<>Read Now</>} 
                    alink={props.link} 
                    classes="blue"
                />
            </div>
        </div> 
        </Fade>
    )
}