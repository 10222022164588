import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";
import TeamIn from "./teamin";
import React from "react"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function Team(){
    return(
        <div className="wid100 team bluebg secme" id="team">
            <Title titlestring="Meet Our Team" classes="justify-center align-center whiteme"/>      

            {
                window.innerWidth > 480 ?             
                    <div className="flex wid85 mob100 align-center justify-around secme row-wrap">                
                        <TeamIn img="saurav.png" name="Saurav Purkayastha" design="Founder"/>
                        <TeamIn img="anup.png" name="Anup Ponnuveetil" design="Co-founder"/>
                        <TeamIn img="pooja.png" name="Pooja Datta Yadav" design="City Coordinator"/>
                        <TeamIn img="aishwarya.png" name="Aishwarya Khemkar" design="Expansion Manager"/>
                        <TeamIn img="aishwaryachuri.png" name="Aishwarya Prashant Churi" design="Travel Associate"/>
                        <TeamIn img="rikita.png" name="Rikita Lakdawala" design="Product Manager"/>
                        <TeamIn img="jahnavi.png" name="Jahnavi Shah" design="Digital Marketing Manager"/>
                        <TeamIn img="neel.png" name="Neel Deepak Bijam" design="Travel Associate"/>
                        <TeamIn img="prashantha.png" name="Prashantha G" design="City Coordinator"/>
                        <TeamIn img="salman.png" name="Shaik Salman" design="Business Development Manager"/>
                        <TeamIn img="fairoza.png" name="Fairoza Bi" design="Customer Relationship Manager"/>
                        <TeamIn img="gazala.png" name="Khan Gazala Abdullah" design="Travel Associate"/>
                        <TeamIn img="nandhu.png" name="Nandhu Vijayan" design="Travel Associate"/>
                        <TeamIn img="diksha.png" name="Diksha Sahgal" design="Brand Manager"/>                
                    </div> :
                    <Masonry columnsCount={2} className="align-center">
                        <TeamIn img="saurav.png" name="Saurav Purkayastha" design="Founder"/>
                        <TeamIn img="anup.png" name="Anup Ponnuveetil" design="Co-founder"/>
                        <TeamIn img="pooja.png" name="Pooja Datta Yadav" design="City Coordinator"/>
                        <TeamIn img="aishwarya.png" name="Aishwarya Khemkar" design="Expansion Manager"/>
                        <TeamIn img="aishwaryachuri.png" name="Aishwarya Prashant Churi" design="Travel Associate"/>
                        <TeamIn img="rikita.png" name="Rikita Lakdawala" design="Product Manager"/>
                        <TeamIn img="jahnavi.png" name="Jahnavi Shah" design="Digital Marketing Manager"/>
                        <TeamIn img="neel.png" name="Neel Deepak Bijam" design="Travel Associate"/>
                        <TeamIn img="prashantha.png" name="Prashantha G" design="City Coordinator"/>
                        <TeamIn img="salman.png" name="Shaik Salman" design="Business Development Manager"/>
                        <TeamIn img="fairoza.png" name="Fairoza Bi" design="Customer Relationship Manager"/>
                        <TeamIn img="gazala.png" name="Khan Gazala Abdullah" design="Travel Associate"/>
                        <TeamIn img="nandhu.png" name="Nandhu Vijayan" design="Travel Associate"/>
                        <TeamIn img="diksha.png" name="Diksha Sahgal" design="Brand Manager"/>    
                    </Masonry>
            }
        </div>
    )
}

export default Team;